<template>
  <section class="app-ecommerce-details">
    <b-card>
      <b-card-body>
        <div class="">
          <b-button
            size="sm"
            variant="outline-primary mr-1"
            class="link mb-2"
            @click="openNewBanner"
          >
            <feather-icon
              icon="CameraIcon"
              size="12"
            />
            Banner qo'shish
          </b-button>
        </div>
        <div
          v-for="(banner, index) in banners"
          :key="index"
          class="d-bloc text-center"
        >
          <hr>
          <h3 class="text-center fs20">
            {{ banner.order }}-Banner
            <feather-icon
              icon="Trash2Icon"
              class="text-danger"
              size="18"
              @click="deleteBanner(banner.id)"
            />
          </h3>
          <div class="d-flex justify-content-between">
            <p>
              <b>Link:</b>
              <a
                :href="banner.link"
                class="link"
              >{{ banner.link }}</a>
            </p>
            <span
              class="link"
              @click="editBanner(banner)"
            >
              <feather-icon
                icon="EditIcon"
                size="12"
              />
              Tahrirlash
            </span>
          </div>
          <div class="d-flex justify-content-center flex-wrap">
            <!-- Desktop Photo -->
            <div class="text-center m-2">
              <div class="banner-img-wrapper">
                <template v-if="banner.desktop_url">
                  <img
                    :src="storageUrl + banner.desktop_url"
                    height="162"
                    width="162"
                    class="banner-img"
                  >
                </template>
              </div>
              <span class="upload-change-label">
                Desktop banner ko'rinishi
              </span>
            </div>

            <!-- Mobile Photo -->
            <div class="text-center m-2">
              <div class="banner-mobile-img-wrapper">
                <template v-if="banner.mobile_url">
                  <img
                    :src="storageUrl + banner.mobile_url"
                    height="162"
                    width="162"
                    class="banner-mobile-img"
                  >
                </template>
                <template v-else>
                  <b-img
                    class="banner-mobile-img"
                    height="120"
                    :src="require('@/assets/images/avatars/banner-6.jpg')"
                    alt="Left image"
                  />
                </template>
              </div>
              <span class="upload-change-label"> Mobil banner ko'rinishi </span>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <ImageUpload
      v-if="imageModal"
      :model="bannerInfo"
      :banners-count="banners.length"
      @close="closeUpload"
    />
  </section>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BCard,
  BCardBody,
  BImg,
} from 'bootstrap-vue'
import { showToast } from '@/utils/toast'

export default {
  components: {
    BCard,
    BCardBody,
    BImg,
    ImageUpload: () => import('./imagesUplad.vue'),
  },
  setup() {
    const banners = ref([])
    const mainPhoto = ref(null)
    const imageModal = ref(false)
    const isEdit = ref(false)
    const bannerInfo = ref()
    const fetchBanners = () => {
      store
        .dispatch('shop/index', { per_page: 999 })
        .then(response => {
          banners.value = response.data.data
        })
        .catch(error => {
          banners.value = []
        })
    }
    const editBanner = banner => {
      bannerInfo.value = banner
      imageModal.value = true
    }
    const openNewBanner = () => {
      bannerInfo.value = {}
      imageModal.value = true
    }
    const selectedColor = ref(null)
    const characteristicsInfoList = ref(null)
    fetchBanners()
    const deleteBanner = id => {
      store.dispatch('shop/destroy', id).then(response => {
        showToast('success', 'Успешно удалено')
        fetchBanners()
      })
      // $confirm(this.$t('Вы действительно хотите удалить?'), {
      //   title: this.$t('Подтвердите'),
      //   size: 'sm',
      //   variant: 'warning',
      //   okVariant: 'primary',
      //   okTitle: this.$t('Да'),
      //   cancelTitle: this.$t('Нет'),
      //   cancelVariant: 'outline-secondary',
      //   hideHeaderClose: false,
      //   centered: true,
      // })
      //   .then(value => {
      //     store.dispatch('shop/destroy', { id }).then(response => {
      //       showToast('success', this.$t('Успешно удалено'))
      //       fetchBanners()
      //     })
      //   })
    }
    const storageUrl = `${process.env.VUE_APP_BASE_URL}storage/`
    const openEditor = () => {
      isEdit.value = true
    }
    const saveDescription = () => {
      isEdit.value = false
    }
    const closeUpload = () => {
      imageModal.value = false
      fetchBanners()
    }
    return {
      banners,
      imageModal,
      editBanner,
      bannerInfo,
      openNewBanner,
      isEdit,
      storageUrl,
      mainPhoto,
      selectedColor,
      characteristicsInfoList,
      openEditor,
      saveDescription,
      closeUpload,
      deleteBanner,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.description-show {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 10px;
}

.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  display: block;
  border: 1px solid rgba(30, 30, 30, 0.3);

  &-wrapper {
    margin: 0 auto 1rem;
    width: 1058px;
    height: 352px;
    zoom: 0.9;
  }
}

.banner-mobile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  display: block;
  border: 1px solid rgba(30, 30, 30, 0.3);

  &-wrapper {
    margin: 0 auto 1rem;
    width: 350px;
    // height: 207px;
    zoom: 0.9;
  }
}
</style>
